import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Container, Table } from "react-bootstrap";
import ComponentSearch from "../../../../components/componentSearch/ComponentSearch";
import LoadingUI from "../../../../components/LoadingUI/LoadingUI";
import TableLineItemLoading from "../../../../components/table-line-item-loading/TableLineItemLoading";
import ObserverTarget from "../../../../components/observerTarget/ObserverTarget";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Customers = () => {
  const [customersData, setCustomersData] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const apiCallDelay = useRef(undefined);

  const handleSearch = (e) => {
    let query = e.target.value;
    setCustomersData(undefined);
    setSearching(true);
    setSearchKey(query);
    apiCallDelay?.current && clearTimeout(apiCallDelay.current);
    apiCallDelay.current = setTimeout(() => {
      getItems(1, {
        name: query,
      });
    }, 1500);
  };

  const getItems = (page, filters) => {
    setFetchingData(true);
    axios
      .get("/api/list-users", {
        params: {
          page: page ?? 1,
          ...filters,
        },
      })
      .then((response) => {
        const apiResult = response?.data?.data;
        if (page > 1) {
          setCustomersData((prev) => ({
            ...apiResult,
            data: [...prev?.data, ...apiResult?.data],
          }));
          return;
        }
        setCustomersData(apiResult);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFetchingData(false);
        setSearching(false);
      });
  };

  useEffect(() => {
    getItems();
  }, []);

  if (fetchingData && !customersData)
    return <LoadingUI message={"Looking for result..."} />;

  return (
    <Container className="d-flex flex-column w-100 flex-fill py-4" fluid>
      <div className="mb-3">
        <p className="h5">Customers</p>
        <p className="text-muted small">Manage customers</p>

        <div className="d-flex flex-wrap justify-content-between align-items-center gap-1">
          <ComponentSearch value={searchKey} onChange={handleSearch} />
        </div>
      </div>

      <Card className="d-flex flex-column w-100 flex-fill">
        <Card.Body className="d-flex flex-column w-100 flex-fill p-0 bg-white">
          <Table
            className="theme-data-table small align-middle mb-0 flex-fill"
            striped
            bordered
            hover
            responsive
          >
            <thead className="text-muted text-uppercase small">
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Verified</th>
                <th>Phone</th>
                <th>Plan</th>
                <th>Purchased Date</th>
                <th>Expiry Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {searching ? (
                <TableLineItemLoading colspan={9} row={30} />
              ) : customersData?.total > 0 ? (
                customersData?.data?.map((user, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>{user.name}</td>
                      <td className="text-capitalize">{user.role}</td>
                      <td>{user.email ?? "-"}</td>
                      <td className="text-center">
                        {user.email_verified_at ? (
                          <FaCheckCircle className="text-success" />
                        ) : (
                          <FaTimesCircle className="text-danger" />
                        )}
                      </td>
                      <td>{user.contact_number ?? "-"}</td>
                      <td>
                        {user?.active_plan?.plan ? (
                          <Badge
                            bg={
                              user.active_plan.plan === "pro"
                                ? "success"
                                : "primary"
                            }
                            className="text-capitalize"
                          >
                            {user.active_plan.plan}
                          </Badge>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{user?.active_plan?.purchased_date ?? "-"}</td>
                      <td>{user?.active_plan?.expiry_date ?? "-"}</td>
                      <td>
                        {user?.active_plan?.expiry_date ? (
                          user.active_plan.package_is_active ? (
                            <Badge bg="success">Active</Badge>
                          ) : (
                            <Badge bg="danger">Expired</Badge>
                          )
                        ) : (
                          <Badge bg="warning">Not found</Badge>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th colSpan={9} className="bg-warning text-black">
                    No results found
                  </th>
                </tr>
              )}
            </tbody>
          </Table>

          {fetchingData ? (
            <div className="text-center small p-2">loading...</div>
          ) : customersData?.total > 0 &&
            customersData?.current_page !== customersData?.last_page ? (
            <ObserverTarget
              fetchData={() =>
                getItems(customersData?.current_page + 1, {
                  name: searchKey,
                })
              }
            />
          ) : null}
        </Card.Body>
        {!searching && customersData?.total > 0 ? (
          <Card.Footer className="border-0 bg-white">
            <p className="very-small m-0 text-center text-muted">
              Showing {customersData?.to} / {customersData.total}
            </p>
          </Card.Footer>
        ) : null}
      </Card>
    </Container>
  );
};

export default Customers;
