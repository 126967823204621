import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { PublicItemContenxt } from "../../contexts/PublicItemContenxt";
import { FaShoppingCart } from "react-icons/fa";

const Cart = ({ mobileBottomNav }) => {
  let params = useParams();
  let { publicItems } = useContext(PublicItemContenxt);
  let { store } = publicItems;
  const { cart } = useContext(CartContext);
  const [cartLength, setCartLength] = useState(0);

  useEffect(() => {
    if (cart) {
      let total = 0;
      const storeID = store?.id || null;
      cart
        .filter((item) => item.store_id === storeID)
        .map((item) => (total = parseInt(total) + parseInt(item.count)));
      setCartLength(total);
    }
  }, [cart, store]);

  return mobileBottomNav ? (
    <>
      {cartLength > 0 && (
        <Badge
          bg="danger"
          className="position-absolute"
          style={{
            right: "-15px",
            top: "-10px",
          }}
        >
          {cartLength}
        </Badge>
      )}
      <FaShoppingCart size={22} />
      <span className="small">Cart</span>
    </>
  ) : (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="cart-tooltip">
          {cartLength < 1
            ? "Your cart is empty"
            : `You have ${cartLength} item${cartLength > 1 ? "s" : ""}`}
        </Tooltip>
      }
    >
      <Button
        variant="dark"
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          boxShadow: "none",
        }}
      >
        <FontAwesomeIcon icon={faShoppingBag} />
        {cartLength > 0 && <Badge bg="danger">{cartLength}</Badge>}
      </Button>
    </OverlayTrigger>
  );
};

export default Cart;
