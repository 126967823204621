import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { lazy, useContext, useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "./layout/DashboardLayout";
import GuestRequire from "./helper/GuestRequire";
import AuthRequire from "./helper/AuthRequire";
import StoreRequire from "./helper/StoreRequire";
import { ItemContext } from "./contexts/ItemContenxt";
import { CartContext } from "./contexts/CartContext";
import { PublicItemContenxt } from "./contexts/PublicItemContenxt";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import AdminDashboardLayout from "./module/admin/page/AdminDashboardLayout";
import AdminDashboard from "./module/admin/page/dashboard/AdminDashboard";
import Customers from "./module/admin/page/customers/Customers";
import Invoice from "./module/admin/page/accounts/Invoice";
import SuspenseElelement from "./components/SuspenseElelement/SuspenseElelement";
import Stores from "./pages/stores/Stores";
import DashboardMainPage from "./pages/dashboard/DashboardMainPage";
import { LoginContext } from "./contexts/LoginContext";
import SessionOutLogInForm from "./components/SessionOutLogInForm/SessionOutLogInForm";

// lazy load components
const Checkout = lazy(() => import("./pages/checkout/Checkout"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Category = lazy(() => import("./pages/category/Category"));
const Product = lazy(() => import("./pages/product/Product"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Billing = lazy(() => import("./pages/billing/Billing"));
const BillPreview = lazy(() => import("./pages/bill-preview/BillPreview"));

const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const Plans = lazy(() => import("./pages/plans/Plans"));
const Search = lazy(() => import("./pages/search/Search"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const StoreDetails = lazy(() => import("./pages/storeDetails/StoreDetails"));
const ProductList = lazy(() => import("./pages/productList/ProductList"));

const Login = lazy(() => import("./pages/auth/login/Login"));
const Register = lazy(() => import("./pages/auth/register/Register"));
const AccountRecovery = lazy(() =>
  import("./pages/auth/AccountRecovery/AccountRecovery")
);
const ResetPassword = lazy(() =>
  import("./pages/auth/ResetPassword/ResetPassword")
);

// settings module
const Tax = lazy(() => import("./module/settings/tax/Tax"));
const TaxGroup = lazy(() => import("./module/settings/tax-group/TaxGroup"));
// settings module end

function App() {
  let { user } = useContext(LoginContext);
  const [items, setItems] = useState(useContext(ItemContext));
  const [publicItems, setPublicItems] = useState(
    useContext(PublicItemContenxt)
  );
  const [cart, setCart] = useState(useContext(CartContext));

  useEffect(() => {
    let cartItems = localStorage.getItem("MABIZ_DIGITAL_STORE_APP_CART");
    cartItems && setCart(JSON.parse(cartItems));
  }, []);

  return (
    <div className="App">
      <ItemContext.Provider value={{ items, setItems }}>
        <PublicItemContenxt.Provider value={{ publicItems, setPublicItems }}>
          <CartContext.Provider value={{ cart, setCart }}>
            <Router>
              <Routes>
                <Route element={<DashboardLayout />}>
                  <Route element={<SuspenseElelement />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="subscribe" element={<Plans />} />
                    <Route element={<StoreRequire />}>
                      <Route path="search/:storeID" element={<Search />} />
                      <Route path="cart/:storeID" element={<Cart />} />
                      <Route path="store/:storeID" element={<StoreDetails />} />
                      <Route
                        path="products/:storeID/:categoryID"
                        element={<ProductList />}
                      />
                    </Route>

                    <Route element={<GuestRequire />}>
                      <Route path="login" element={<Login />} />
                      <Route path="register" element={<Register />} />
                      <Route
                        path="account-recovery"
                        element={<AccountRecovery />}
                      />
                      <Route
                        path="reset-password/:token"
                        element={<ResetPassword />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="*"
                    element={
                      <Container className="mt-4 text-center">
                        <h1>404</h1>
                      </Container>
                    }
                  />
                </Route>

                <Route element={<AuthRequire />}>
                  <Route element={<SuspenseElelement />}>
                    <Route path="checkout/:plan" element={<Checkout />} />
                    <Route path="dashboard" element={<Dashboard />}>
                      <Route index element={<DashboardMainPage />} />
                      <Route path="warehouse" element={<Stores />} />
                      <Route path="inventory">
                        <Route path="category" element={<Category />} />
                        <Route path="items" element={<Product />} />
                      </Route>
                      <Route path="settings">
                        <Route path="tax" element={<Tax />} />
                        <Route path="tax-group" element={<TaxGroup />} />
                      </Route>
                      <Route path="profile" element={<Profile />} />
                      <Route path="billing" element={<Billing />} />

                      {/* admin area  start*/}
                      <Route path="admin">
                        <Route
                          index
                          element={<Navigate to="/dashboard" replace={true} />}
                        />
                        <Route path="customers" element={<Customers />} />
                        <Route
                          path="warehouse"
                          element={<Stores listForAdmin />}
                        />
                        <Route path="accounts/invoice" element={<Invoice />} />
                      </Route>
                      {/* admin area  end*/}
                    </Route>
                    <Route
                      path="dashboard/billing/view-invoice/:invoiceNumber"
                      element={<BillPreview />}
                    />
                  </Route>
                  {/* <Route path="dashboard" element={<SuspenseElelement />}>
                      <Route path="billing">
                        <Route index element={<Billing />} />
                        <Route
                          path="view-invoice/:invoiceNumber"
                          element={<BillPreview />}
                        />
                      </Route>
                    </Route> */}
                </Route>
              </Routes>
            </Router>
          </CartContext.Provider>
        </PublicItemContenxt.Provider>
      </ItemContext.Provider>
      <div>
        <ToastContainer />
      </div>

      <SessionOutLogInForm show={user?.sessionExpired} />
    </div>
  );
}

export default App;
