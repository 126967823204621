import React from "react";
import { Button } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

export default function ShowButton({ onClick, title, label }) {
  return (
    <Button
      size="sm"
      variant="primary"
      className="m-1 box-shadow-none"
      title={title || "Show Item"}
      onClick={onClick}
    >
      <FaEye size={10} /> {label ? <span>{label}</span> : null}
    </Button>
  );
}
